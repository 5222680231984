import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  AnswerView,
  IconButtonComponent,
  RadioButtonComponent,
} from "../../atom";
import _ from "lodash";

export default ({
  item,
  index,
  onclick = () => {},
  onPressDelete = () => {},
  onPressEdit = () => {},
}) => {
  return (
    <Box className="admin-question-wrap">
      <Box className="admin-question">
        <Box sx={{paddingInlineStart:2}}>
          <div
            dangerouslySetInnerHTML={{
              __html: `${index + 1 < 10 ? `0${index + 1}` : index + 1}. ${_.get(item, "question", "")}`,
            }}
          />
        </Box>
      </Box>
      <Box className="admin-question-btn-section">
        <Box className="admin-question-btn">
          <IconButtonComponent
            btntitle={"View"}
            btnType={"viewIconbtn"}
            onclick={() => {
              // setViewQuestion(true)
              onclick();
            }}
          />

          <IconButtonComponent
            // btntitle={"edit"}
            btnType={"editbtn"}
            onclick={() => {
              onPressEdit();
            }}
          />
          <IconButtonComponent
            onclick={onPressDelete}
            btntitle={"Delete"}
            btnType={"deleteIconbtn"}
          />
        </Box>
      </Box>
    </Box>
  );
};
