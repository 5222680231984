import { Map } from 'immutable';
import _ from 'lodash';
import { ModuleEvents } from './Actions';
//import { ModuleEvents as SigninEvent } from "../signin/Actions";

const InitialState = Map({
  loadingAction: { loading: false, action: {} },
  stdquizList: [],
  startData: {},
  questions: [],
  attemptsData: [],
  completeData: [],
  quizList: [],
  quizData: {},
  quizSubmissionHistory: [],
  courseWeekQuiz: [],
  createdQuizData: {},
  questionAndAnswersList: [],
  assessmentDetails: {},
  quizFeedback: {},
  assessmentFormQnR: {},
  studentAnswers: {},
  isQuizStarted: false,
  updateQuiz:[],
  graphData:[],
});



export const Reducer = (state = InitialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case ModuleEvents.STD_GET_QUIZ_LIST: {
      if (payload && !payload.error && payload.status_code && payload.status_code == 1) {
        const data = _.get(payload, 'data', {});
        return state.set('stdquizList', data);
      }
      return state
    }
    case ModuleEvents.STD_START_QUIZ: {
      if (payload && !payload.error && payload.status_code && payload.status_code == 1) {
        const data = _.get(payload, 'data', {});
        return state.set('startData', data).set('isQuizStarted', true);
      }
      return state
    }
    case ModuleEvents.STD_GET_QUESTIONS: {
      if (payload && !payload.error && payload.status_code && payload.status_code == 1) {
        const data = _.get(payload, 'data', {});
        return state.set('questions', data);
      }
      return state
    }
    case ModuleEvents.STD_GET_ATTEMPTS: {
      if (payload && !payload.error && payload.status_code && payload.status_code == 1) {
        const data = _.get(payload, 'data', {});
        return state.set('attemptsData', data);
      }
      return state
    }
    case ModuleEvents.STD_COMPLETE_QUIZ: {
      if (payload && !payload.error && payload.status_code && payload.status_code == 1) {
        const data = _.get(payload, 'data', {});
        return state.set('completeData', data);
      }
      return state
    }
    case ModuleEvents.STD_STUDENT_ANSWERS: {
      if (payload && !payload.error && payload.status_code && payload.status_code == 1) {
        const data = _.get(payload, 'data', {});
        return state.set('studentAnswers', data);
      }
      return state
    }
    case ModuleEvents.GET_ALL_QUIZE_LIST: {
      if (payload && !payload.error && payload.status && payload.status_code == 1) {
        const list = _.get(payload, 'data', []);
        return state.set('quizList', list)
      }
      return state
    }

    case ModuleEvents.GET_QUIZ_DATA: {
      if (payload && !payload.error && payload.status && payload.status_code == 1) {
        const data = _.get(payload, 'data', {});
        return state.set('quizData', data)
      }
      return state
    }

    case ModuleEvents.GET_QUIZ_SUBMISSION_HISTORY: {
      if (payload && !payload.error && payload.status && payload.status_code == 1) {
        const list = _.get(payload, 'data', []);
        return state.set('quizSubmissionHistory', list)
      }
      return state
    }

    case ModuleEvents.GET_COURSE_WEEK_DATA_LIST: {
      if (payload && !payload.error && payload.status && payload.status_code == 1) {
        const data = _.get(payload, 'data', []);
        return state.set('courseWeekQuiz', data)
      }
      return state
    }

    case ModuleEvents.CREATE_QUIZE: {
      if (payload && !payload.error && payload.status && payload.status_code == 1) {
        const data = _.get(payload, 'data', {});
        return state.set('createdQuizData', data)
      }
      return state
    }

    case ModuleEvents.GET_QUIZ_AND_ANSWER_BY_QUIZ_ID: {
      if (payload && !payload.error && payload.status && payload.status_code == 1) {
        const data = _.get(payload, 'data', []);
        return state.set('questionAndAnswersList', data)
      }
      return state
    }


    case ModuleEvents.GET_ASSESSMENT_FORM_DETAILS: {
      if (payload && !payload.error && payload.status && payload.status_code == 1) {
        const data = _.get(payload, 'data', []);
        return state.set('assessmentDetails', data)
      }
      return state
    }

    case ModuleEvents.ADD_FEEDBACK_QUIZ_ADMIN: {
      if (payload && !payload.error && payload.status && payload.status_code == 1) {
        const data = _.get(payload, 'data', []);
        return state.set('quizFeedback', data);
      }
      return state
    }

    case ModuleEvents.GET_ASSESSMENT_QUESTION_N_REASON: {
      if (payload && !payload.error && payload.status && payload.status_code == 1) {
        const data = _.get(payload, 'data', []);
        return state.set('assessmentFormQnR', data);
      }
      return state
    }

    case ModuleEvents.CALL_FOR_CHANGEING_START_QUIZ_STATE: {
      return state.set('isQuizStarted',payload);
    }

    case ModuleEvents.GET_ASSESSMENT_QUESTION_N_REASON_ADMIN: {
      if (payload && !payload.error && payload.status && payload.status_code == 1) {
        const data = _.get(payload, 'data', []);
        return state.set('assessmentFormQnR', data);
      }
      return state
    }

    case ModuleEvents.UPDATE_QUIZ_ADMIN: {
      if (payload && !payload.error && payload.status && payload.status_code == 1) {
        const data = _.get(payload, 'data', {});
        return state.set('updateQuiz', data);
      }
      return state
    }

    case ModuleEvents.GET_ATTEMPT_GRAPGH: {
      if (payload && !payload.error && payload.status && payload.status_code == 1) {
        const data = _.get(payload, 'data', {});
        return state.set('graphData', data);
      }
      return state
    }
  }




  return state;
};
