import React, { useState, useEffect, useRef } from "react";
import { Box, DialogActions, DialogContent, Button } from "@mui/material";
import { TextInputComponent } from "../../atom";
import TextButtonComponet from "../../atom/Buttons/TextButton";
import { Actions } from "../../../core/modules/Actions";
import { useDispatch } from 'react-redux';
import { getText, getFile } from "../../../core/Constant";



const VideoForm = ({ onClose , isClsose}) => {
    const dispatch = useDispatch();
    const inputRef = useRef();
    const imageRef = useRef();

    const [title, setTitle] = useState('');
    const [titleError, setTitleError] = useState(false);
    const [titalValid, setTitleVaid] = useState(false);

    const [video, setVideo] = useState('');
    const [videoError, setVideoError] = useState(false);
    const [videoErrorMsg, setVideoErrorMsg] = useState('');
    const [isVideoValid, setIsVideoVaid] = useState(false);

    const [thumbnail, setThumbnail] = useState('');
    const [thumbnailError, setThumbnailError] = useState(false);
    const [thumbnailErrorMsg, setThumbnailErrorMsg] = useState('');
    const [isThumbnailValid, setThumbNailValid] = useState(false);

    const [videoUploadStatus, setVideoUploadStatus] = useState("");
    const [videoUploadBtnStatus, setVideoUploadBtnStatus] = useState(false);

    useEffect(() => {
        setTitle('');
        setVideo('');
        setThumbnail('');
        setVideoUploadStatus("");
        inputRef.current.value = "";
        imageRef.current.value = "";
        setVideoUploadBtnStatus(false);
        setTitleError(false);
        setVideoError(false);
        setThumbnailError(false);
        setTitleVaid(false);
        setThumbNailValid(false);
        setIsVideoVaid(false);

    }, [isClsose]);

    const onTitleChange = (e) => {
        const text = getText(e)
        setTitle(text);
        setTitleError(false)
        const isValid = text?.length > 0;
        setTitleVaid(isValid);

    }

    const onThumbnailChange = (e) => {
        const file = getFile(e);
        setThumbnailError(false);
        const isValid = file != "" && file != undefined;
        setThumbNailValid(isValid)
        setThumbnail(isValid ? file : '');
    }

    const onVideoChange = (e) => {
        const file = getFile(e)
        const isValid = file != "" && file != undefined;
        setIsVideoVaid(isValid)
        setVideoError(false);
        setVideo(isValid ? file : '');
    }

    const adminVideoUpload = () => {

        if (isThumbnailValid && isVideoValid && titalValid) {
            setVideoUploadStatus("Please be patient. Don't close window, Upload in progress.");
            setVideoUploadBtnStatus(true);
            const data = new FormData();
            data.append("video", video);
            data.append("title", title);
            data.append("thumbnail", thumbnail);

            dispatch(Actions.video.uploadVideo(data))
        } else {
            if (!titalValid) {
                setTitleError(true);
            }
            if (!isVideoValid) {
                setVideoErrorMsg('Please select the video')
                setVideoError(true)
            }
            if (!isThumbnailValid) {
                setThumbnailErrorMsg('Please select the thumbnail');
                setThumbnailError(true)
            }
        }


    }
    return (
        <form>
            <DialogContent>
                <Box>
                    <Box>
                        <div style={{ marginBottom: '5px' }}>
                            <TextInputComponent
                                label={"Title"}
                                isError={titleError}
                                placeholder="Enter video title"
                                name={"title"}
                                value={title}
                                error={"Please add the video title"}
                                onchange={onTitleChange}
                            />
                        </div>
                        <div style={{ marginBottom: '5px' }}>
                            <Box  style={{padding:10}}>
                            <label className="form-label">Video</label>
                            <input
                                className="form-control"
                                type={"file"}
                                accept="video/*"
                                ref={inputRef}
                                onChange={onVideoChange}
                                required
                            />
                            {videoError ? <p className="input-error-text">{videoErrorMsg}</p> : null}
                            </Box>
                        </div>
                        <div style={{ marginBottom: '5px' }}>
                            <Box  style={{padding:10}}>
                            <label className="form-label">Thumbnail</label>
                            <input
                                className="form-control"
                                type={"file"}
                                accept="image/*"
                                ref={imageRef}
                                onChange={onThumbnailChange}
                            />
                            {thumbnailError ? <p className="input-error-text">{thumbnailErrorMsg}</p> : null}
                            </Box>
                        </div>

                    </Box>
                </Box>
                <Box>
                    <b> <p className="text-center" style={{ color: 'red' }}>{videoUploadStatus}</p></b>
                </Box>
                <Box mt={5} sx={{ display: "flex", gap: 5, width: 1, justifyContent: 'center' }}>
                    <TextButtonComponet text={"Cancel"} classStyle="btn btn-secondary" onButtonClick={() => onClose()} />
                    <TextButtonComponet text="Upload" onButtonClick={() => adminVideoUpload()} isDisabled={videoUploadBtnStatus} />
                </Box>

            </DialogContent>
        </form>
    );
}

export default VideoForm;
